import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import DefaultTheme from "../../themes";

import { ReactComponent as SvgComponent } from "../../assets/images/result_screens/something_wrong.svg";
import Button from "../../components/Button";
import AppLayout from "../../appLayout/AppLayout";
import authService from "../../services/auth-service";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import { useHistory } from "react-router-dom";
import { noResponseParams } from "../../components/Helpers/ResultMessagesHelper";

export default function ResendCode() {
    const { t } = useTranslation();
    const history = useHistory();
    const navigateTo = history.location?.backTo;
    const id = history.location?.id;
    const email = history.location?.email;
    const card = history.location?.card;
    const purchase = history.location?.purchase;
    const notifications = history.location?.notifications;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(t("errors:validationCodeExpired"));

    async function resendCode() {
      if(!email){
        history.push("/ForgotPassword")
      }
      setLoading(true);
      let res = await authService.newValidationCode({
        email: email,
      });
      if (res.success) {
        history.push({
          pathname: navigateTo,
          email: email,
          successAlert: true,
          id: id,
          card: card,
          purchase: purchase,
          notifications: notifications
        });
      } else if (res.errorMessage.noResponse) {
        history.push("/error", {
          params: noResponseParams,
        });
      } else {
        setErrorMessage(res.errorMessage.message);
      }
      setLoading(false);
    }

    return (
      <AppLayout
        header={false}
        style={{ backgroundColor: "#fff" }}
      >
       <View style={{ flex: 1, display: "flex" }}>
          <View style={{ alignSelf: "center", marginTop: "24vh" }}>
              <SvgComponent />
          </View>
          <View style={styles.text_container}>
            <H1Title>{t("resultScreens:errorTitle")}</H1Title>
            <Paragraph>{errorMessage}</Paragraph>
          </View>
          <BottomBtnContainer style={{ marginBottom: "10%" }}>
            <Button
              text={t("buttons:resendValidationCode")}
            onPress={() => {
              resendCode();
            }}
            type="PRIMARY"
            loading={loading}
            element={loading ? "" : null}
          />
        </BottomBtnContainer>
        </View>
      </AppLayout>
    );
}

const styles = StyleSheet.create({
  image_container: {
    flex: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  text_container: {
    flex: 2,
    justifyContent: "flex-start",
    paddingTop: 30,
  },
});
