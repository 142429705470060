export default {
  slide01: "Une pause lunch?",
  slide02: "Envie d'un snack?",
  slide03: "Un repas rapide?",
  slide04: "Tu veux changer ta routine?",
  slide05: "Peut-être quelque chose de sucré?",
  slide06: "Ou une option plus saine?",
  logoutModalTitle: "Tu dois partir? Pas de problème",
  logoutModalText:
    "J'espère que tu as apprécié l'utilisation de ton microstore. A bientôt!",
};		