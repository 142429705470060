export default {
  userNotValidated: "Gebruiker niet gevalideerd",
  emailEmpty: "Vul de e-mail in.",
  emailInvalid: "Het ingevoerde e-mailadres is ongeldig.",
  emailDuplicated: "Het e-mailadres is al in gebruik.",
  passwordEmpty: "Vul het wachtwoord in.",
  passwordWrong: "Voer het juiste wachtwoord in.",
  currentPassword: "Voer het huidige wachtwoord in.",
  passwordInfo:
    "Het wachtwoord moet minstens 8 tekens lang zijn, waaronder minstens één cijfer of symbool.",
  noMatchEmailAndPassword:
    "Het door u ingevoerde e-mailadres en wachtwoord komen niet overeen met onze gegevens. Controleer nogmaals en probeer het opnieuw.",
  required: "Vereist.",
  phoneInvalid: "Het ingevoerde telefoonnummer is ongeldig.",
  phoneDuplicated: "Dit telefoonnummer is al in gebruik.",
  validationCodeWrong:
    "De ingevoerde validatiecode is verkeerd, voer de juiste code in.",
  validationCodeExpired: "De verificatiecode is verlopen.",
  messageCharacters: "Uw bericht moet minimaal 10 tekens bevatten.",
  cardNumberValidation: "Voer een geldig kaartnummer in.",
  cardExpiryDateValidation: "De vervaldatum moet deze maand of later zijn.",
  cardCVCValidation: "CVC moet 3 of 4 cijfers hebben.",
  invalidMicrostoreCode: "Voer een geldig legitimatiebewijs in.",
};