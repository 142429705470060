import AuthService from "../services/auth-service";

import { createContext, useState } from "react";

export const AuthContext = createContext({
  isAuthenticated: false,
  isAuthenticatedSignUp: false,
  authenticate: (credentials) => { },
  authenticateSignUp: (credentials) => { },
  exitSignUp: (credentials) => { },
  logout: () => { },
});

function AuthContextProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [isAuthenticatedSignUp, setIsAuthenticatedSignUp] = useState();

  function authenticate() {
    setIsAuthenticated(true);
  }

  function authenticateSignUp() {
    setIsAuthenticatedSignUp(true);
  }

  function exitSignUp() {
    setIsAuthenticatedSignUp(false);
    setIsAuthenticated(true);
  }

  function logout() {
    setIsAuthenticated(false);
    setIsAuthenticatedSignUp(false);
    AuthService.logout();
  }


  const value = {
    isAuthenticated: isAuthenticated,
    isAuthenticatedSignUp: isAuthenticatedSignUp,
    authenticate: authenticate,
    authenticateSignUp: authenticateSignUp,
    exitSignUp: exitSignUp,
    logout: logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;