export default {
  faqs: "FAQs",
  howToUseTheMicrostore: "How to use the microstore",
  contactUs: "Contact Us",
  subject: "Subject",
  message: "Message",
  contactUsOkScreenTitle: "Thank you!",
  contactUsOkScreenText: "We appreciate your contact and we’ll try to answer as quick as possible!",
  privacyPolicy: "Privacy Policy",
  termsConditions: "Terms & Conditions",
  howToUse01Title: "Start Session",
  howToUse01Text: "Scan the QR Code present on the top of the cabinet. ",
  howToUse02Title: "Wait to unlock the doors",
  howToUse02Text:  "After you hear a clicking sound, the doors will open and you can start shopping.",
  howToUse03Title: "Pick the products",
  howToUse03Text: "Have a look inside but don’t forget to keep at least one door open while shopping. ",
  howToUse04Title: "End session",
  howToUse04Text:  "Once you’re done, close the doors, and you’re ready to go. All products you buy are identified.",
  howToUse05Title: "Processing payment",
  howToUse05Text:  "The sessions’ details will be available on your \"My Purchases\" page.",
};