export default {
  userNotValidated: "Utilisateur non validé",
  emailEmpty: "Veuillez remplir l'email.",
  emailInvalid: "L'email saisi n'est pas valide.",
  emailDuplicated: "Cet e-mail est déjà utilisé.",
  passwordEmpty: "Veuillez saisir le mot de passe.",
  passwordWrong: "Veuillez saisir le mot de passe correct.",
  currentPassword: "Entrez le mot de passe actuel.",
  passwordInfo:
    "Le mot de passe doit comporter au moins 8 caractères, dont au moins un chiffre ou un symbole.",
  noMatchEmailAndPassword:
    "L'adresse électronique et le mot de passe que vous avez saisis ne correspondent pas à nos données. Veuillez revérifier et réessayer.",
  required: "Requis. ",
  phoneInvalid: "Le numéro de téléphone saisi n'est pas valide.",
  phoneDuplicated: "Ce numéro de téléphone est déjà utilisé.",
  validationCodeWrong:
    "Le code de validation saisi est incorrect, veuillez saisir le code correct.",
  validationCodeExpired: "Le code de vérification a expiré.",
  messageCharacters: "Votre message doit comporter au moins 10 caractères.",
  cardNumberValidation: "Veuillez insérer un numéro de carte valide.",
  cardExpiryDateValidation:
    "La date d'expiration doit être ce mois-ci ou plus tard.",
  cardCVCValidation: "Le CVC doit comporter 3 ou 4 chiffres.",
  invalidMicrostoreCode: "Veuillez saisir un ID valid.",
};