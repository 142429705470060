export default {
  hello: "Hello",
  languageSelector: "Choose your preferred language",
  english: "English",
  french: "French",
  dutch: "Dutch",
  headerHome: "1, 2, 3 Ready to Go!",
  headerForgotPassword: "Forgot Password",
  headerGettingStarted: "Getting Started!",
  headerOpenMachine: "Open MicroStore",
  headerMicrostoreSession: "MicroStore Session",
  headerHelpAndLegal: "Help & legal",
  headerPayments: "Payment Method",
  headerAccountInfo: "Account Info",
  headerCurrentPassword: "Current Password",
  headerPurchases: "My Purchases",
  headerPurchaseDetail: "Purchase Detail",
  headerAddCreditCard: "Add Credit Card",
  headerAccountValidation: "Account Validation",
  headerSignUp: "Sign Up",
  headerResolutionCenter: "Resolution Center",
  headerCreateTicket: "Create Ticket",
  headerMyTickets: "My Tickets",
  headerChangeLanguage: "Change Language",
  headerDebts: "Debts",
  headerPhoneNumberReview: "Phone Number Review",
};
