export default {
  noPurchasesTitle: "No Purchases yet",
  noPurchasesText:
    "As soon as you complete a purchase, its details will show up here. Go for it!",
  purchase: "Purchase",
  item: "item",
  microstore: "Microstore",
  paymentStatus: "Payment Status",
  pending: "Pending",
  failed: "Failed",
  paid: "Paid",
  gotAnIssue: "Got an issue with this purchase?",
  createATicket: "Create a Ticket",
  partiallyPaid: "Partially Paid",
  inDebt: "In Debt",
  totalPaid: "Total paid",
  debtStatus: "Debt Status",
  partiallySettled: "Partially settled",
  totalSettled: "Total Settled",
  totalInDebt: "Total in debt",
  vatIncl: " VAT incl.",
  vatExcl: "VAT excl.",
};
