import React, { useEffect, useContext } from "react";
import AppLayout from "../../appLayout/AppLayout";
import { useHistory } from "react-router-dom";
import {ReactComponent as SvgComponent} from "../../assets/images/authentication/allSet.svg";
import { View, StyleSheet } from "react-native-web";
import Button from "../../components/Button";
import H1Title from "../../components/UI/H1Title";
import Paragraph from "../../components/UI/Paragraph";
import { useTranslation } from "react-i18next";
import BottomBtnContainer from "../../components/UI/BottomBtnContainer";
import { AuthContext } from "../../store/auth-context";
import AuthService from "../../services/auth-service";
import localStorageUser from "../../localStorage/user";
import BuybyeService from "../../services/buybye-service";

export default function ResultMessageScreen() {
    const history = useHistory();
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const id = history.location?.id;
    const card = history.location?.card;
    const purchase = history.location?.purchase;
    const notifications = history.location?.notifications;

    useEffect(() => {
        async function updateUserWithCard() {
            const getUser = await localStorageUser.getUser();
            if (typeof getUser.result === "string") {
                getUser.result = JSON.parse(getUser.result);
            }
            if (getUser.result){
                let res = await AuthService.updateUser(
                  {
                    otherIdentifiers: [
                      ...(getUser.result?.otherIdentifiers || []),
                      card,
                    ],
                    notifications: notifications,
                  },
                  id
                );
                if (res.success) {
                    await AuthService.getUser({ idUser: id });
                }
                let sendEmail = await BuybyeService.sendEmailReceipt(purchase, {
                  contact: getUser.result.email,
                  registered: true,
                });
            }
        }
        updateUserWithCard();
    }, [])

    return (
        <AppLayout
            header={false}
            style={{ backgroundColor: "#fff" }}
        >
            <View style={{ flex: 1, display: "flex" }}>
                <View style={{ alignSelf: "center", marginTop: "24vh" }}>
                    <SvgComponent />
                </View>
                <View style={styles.text_container}>
                    <H1Title style={{ marginTop: 30 }}>Your Profile is all set</H1Title>
                    <Paragraph>Thank you for registering. From now on you can access to your Breaktime purchase details. </Paragraph>
                </View>
                <BottomBtnContainer style={{ flex: 2 }}>
                    <View style={{ marginTop: 24 }}>
                        <Button
                            text={"Go to Purchase History"}
                            onPress={async () => { await authCtx.authenticate(); history.push("../Purchases") }}
                            type="PRIMARY"

                        />
                    </View>
                </BottomBtnContainer>
            </View>
        </AppLayout>
    );
}

const styles = StyleSheet.create({
    image_container: {
        flex: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    text_container: {
        flex: 2,
        justifyContent: "flex-start",
        paddingTop: 30
    },
});