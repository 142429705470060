export default {
  faqs: "Veelgestelde vragen",
  howToUseTheMicrostore: "Hoe gebruik ik de microstore",
  contactUs: "Neem contact met ons op",
  subject: "Onderwerp",
  message: "Bericht",
  contactUsOkScreenTitle: "Bedankt!",
  contactUsOkScreenText:
    "We stellen uw contact op prijs en we zullen proberen zo snel mogelijk te antwoorden!",
  privacyPolicy: "Privacybeleid",
  termsConditions: "Algemene voorwaarden",
  howToUse01Title: "Scan de QR-code",
  howToUse01Text: "op de deuren van de microstore.",
  howToUse02Title: "Wacht tot de deuren ontgrendeld zijn",
  howToUse02Text:
    "Nadat je een 'klik' hebt gehoord, kun je de deuren openen en beginnen met winkelen.",
  howToUse03Title: "Pak je producten",
  howToUse03Text:
    "Pas op! Vergeet niet ten minste één deur open te houden tijdens het winkelen.",
  howToUse04Title: "Ben je klaar? Sluit maar de deuren.",
  howToUse04Text:
    "De producten die je hebt gekozen worden automatisch geïdentificeerd.",
  howToUse05Title: "Betaling in uitvoering",
  howToUse05Text:
    'De details van je sessie zijn beschikbaar in het tabblad "Mijn aankopen".',
};