export default {
  signUp: "Aanmelden",
  logIn: "Inloggen",
  resetHere: "Reset hier",
  next: "Volgende",
  confirm: "Bevestigen",
  setNewPassword: "Nieuw wachtwoord instellen",
  resendHere: "Hier opnieuw verzenden",
  reviewProfile: "Bekijk profiel",
  letShop: "Laten we winkelen",
  saveAndContinue: "Opslaan en doorgaan",
  resendValidationCode: "Verzend validatiecode opnieuw",
  setUpPaymentMethod: "Betaalmethode instellen",
  skip: "Overslaan",
  skipConfig: "Overslaan",
  accept: "Aanvaarden",
  save: "Redden",
  processing: "Verwerken",
  shopNow: "Winkel nu",
  close: "Sluit",
  submit: "Indienen",
  addCreditCard: "Gewenste betaling toevoegen of wijzigen",
  deleteCreditCard: "Creditcard verwijderen",
  deleteAccount: "Account verwijderen",
  saveChanges: "Opslaan",
  createNewTicket: "Nieuw kaartje maken",
  createTicket: "Probleem melden",
  confirmTicket: "Bevestigen",
  logout: "Uitloggen",
  addPaymentMethod: "Betaalmethode toevoegen",
  addNewPayment: "Nieuwe betaling toevoegen",
  continueSettlement: "Ga verder met schuldenregeling",
  settleTheDebt: "Betaal nu",
  settleDebt: "Betaal nu",
  confirmSettlement: "Bevestigen",
  changePreferredPayment: "Betalingsmiddel toevoegen of wijzigen",
  tryAgain: "Opnieuw proberen",
  saveAndSettleTheDebt: "Spaar en vereffen de schuld",
  cancel: "Annuleren",
  validate: "Valideren",
  goBack: "Ga terug",
  backToAccountInfo: "Terug naar accountinfo",
  backToHome: "Terug naar huis",
  backToPayments: "Terug naar betalingen",
  reviewCreditCardInfo: "Creditcardgegevens bekijken",
  reviewAccountInfo: "Controleer accountgegevens",
  reviewPhoneNumber: "Bekijk telefoonnummer",
  seeDebtDetails: "Zie Schuldgegevens",
  logInReactivateAccount: "Inloggen en account opnieuw activeren",
};
