export default {
  errorTitle: "Uh-Oh",
  userNotValidatedTitle: "Account not validated",
  userNotValidated: "Your account needs to be validated. We will send you an email with a validation code.",
  tryAgainLater: "Please try again later.",
  wrongMachineCode: "The entered code is wrong, please enter again.",
  cannotSatisfyRequest: "We cannot satisfy your request",
  lookingForSolutions:
    "We’re currently looking for solutions. Please try again later.",
  tryAgainToSetup: "Try again to complete the setup process.",
  noResponseTitle: "Something Went Wrong",
  noResponseText:
    "We couldn’t satisfy your request. Please try again. If the problem persists, try later.",
  errorSettlementTitle: "Couldn't settle the debt",
  errorSettlementText:
    "We couldn't settle your debt. Please confirm if you have enough balance on you credit card or try again later.",
  successSettlementTitle: "Debt is settled",
  successSettlementText:
    "Your debt is settled. Now you can resume your shopping.",
  successNewPasswordTitle: "New password set",
  successNewPasswordText:
    "All set! You’re now ready to join us in the world of smart shopping.",
  successSignupAndCardTitle: "You’re all set",
  successSignupAndCardText:
    "Welcome. You’re now ready to join the world of Smart Shopping.",
  successAddCardTitle: "Payment Added",
  successAddCardText:
    "Your payment method has been set up and is ready to be used on your account. Enjoy!",
  errorDebtTitle: "You have a debt",
  errorDebtText: "A debt was created as we couldn’t charge your last purchase. Please go to the debt details and settle it in order to shop again."
};