import React from "react";
import { StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";

const Paragraph = ({ children, style }) => {
  return <Text style={[styles.p, style]}>{children}</Text>;
};

const styles = StyleSheet.create({
  p: {
    fontFamily: DefaultTheme.FONTS.Regular,
    textAlign: "center",
    fontSize: DefaultTheme.FONT_SIZES.p,
    lineHeight: 22.5,
    color: DefaultTheme.COLORS.grey5,
  },
});

export default Paragraph;
