export default {
  errorTitle: "Oh oh",
  userNotValidatedTitle: "Compte non validé",
  userNotValidated:
    "Ton compte doit être validé. Nous t’enverrons un SMS avec un code de validation.",
  tryAgainLater: "Veuillez réessayer plus tard.",
  wrongMachineCode:
    "Le code saisi est incorrect, veuillez le saisir à nouveau.",
  cannotSatisfyRequest: "Nous ne pouvons pas satisfaire votre demande",
  lookingForSolutions:
    "Nous sommes actuellement à la recherche de solutions. Veuillez réessayer plus tard.",
  tryAgainToSetup:
    "Essayez à nouveau pour terminer le processus de configuration.",
  noResponseTitle: "Quelque chose a mal tourné? ",
  noResponseText:
    "Essaye à nouveau.  Si le problème persiste, merci de contacter notre helpdesk à l'adresse mail suivante: carrefourbuybye@gmail.com",
  errorSettlementTitle: "Nous n’avons pas pu régler la dette",
  errorSettlementText:
    "Nous n'avons pas pu régler ta dette. Confirme si le solde de ta carte de crédit est suffisant ou réessaye plus tard.",
  successSettlementTitle: "La dette est réglée",
  successSettlementText:
    "Ta dette est réglée. Tu peux maintenant reprendre tes  achats.",
  successNewPasswordTitle: "Nouveau mot de passe défini!",
  successNewPasswordText:
    "Tout est prêt! Tu es maintenant prêt à nous rejoindre dans le monde du shopping intelligent.",
  successSignupAndCardTitle: "Tu es prêt!",
  successSignupAndCardText:
    "Bienvenue! Tu es maintenant prêt à rejoindre le monde du Smart Shopping.",
  successAddCardTitle: "Paiement ajouté",
  successAddCardText:
    "Ta méthode de paiement a été configurée et est prête à être utilisée sur ton compte. Profites-en!",
  errorDebtTitle: "Vous avez une dette",
  errorDebtText:
    "Une dette a été créée car nous n'avons pas pu facturer votre dernier achat. Veuillez consulter les détails de la dette et régler celle-ci afin d'effectuer de nouveau vos achats.",
};