import React from "react";
import { StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";

const SmallParagraph = ({ children, style }) => {
  return <Text style={[styles.p, style]}>{children}</Text>;
};

const styles = StyleSheet.create({
  p: {
    fontFamily: DefaultTheme.FONTS.Regular,
    textAlign: "left",
    fontSize: DefaultTheme.FONT_SIZES.small,
    lineHeight: 21.6,
    color: DefaultTheme.COLORS.grey5,
    marginTop: 6,
    position:"initial"
  },
});

export default SmallParagraph;
