export default {
  changeDefault: "Modifier le paiement préférentiel",
  choosePreferred: "Choisissez la carte de paiement préférée.",
  preferredPayment: "Paiement par défaut",
  makePreferredPayment: "Définir comme mode de paiement par défaut",
  validThru: "Valable jusqu'au",
  purchasesPaidWithCard: "Achats payés avec cette carte",
  deletePaymentCard: "Supprimer la carte de paiement",
  settleDebt: "Régler la dette",
  withPreferred: " avec ma carte préféré",
  settleDebtModalTitle: "Confirmer le règlement de la dette",
  settleDebtModalTextPart1: "Cette opération permettra de régler ta dette de",
  settleDebtModalTextPart2: "par le biais de ta carte de paiement préférée -",
  deleteCardModalTitle: "Veux-tu supprimer une carte de crédit?",
  deleteCardModalText:
    "Tu peux toujours utiliser les différents modes de paiement que tu as indiqués.",
  paymentMethodMissingTitle: "Uups... Mode de paiement manquant",
  paymentMethodMissingText:
    "Tu dois ajouter une nouvelle carte de crédit ou réintroduire celle qui a été supprimée.",
  skipPaymentSetupTitle: "Sauter la mise en place du paiement?",
  skipPaymentSetupText:
    "Sans méthode de paiement configurée, tu ne pourra pas acheter.",
  enterNameInfo: "Entre le nom tel qu'il figure sur la carte.",
  cardInformation:
    "Cette carte te permet de régler tous tes achats. Tu peux modifier ces informations et ajouter différents modes de paiement ultérieurement. Pour valider ta carte, nous appliquons une retenue d'autorisation temporaire qui te sera remboursée ultérieurement.",
  cardInformation01: " Ce paiement sera considéré comme privilégié.",
  paymentVerificationTitle: "Vérification des paiements",
  paymentVerificationText:
    "Pour confirmer la validité de la carte, une petite somme sera prélevée, qui sera ensuite remboursée.",
  scanCardInfo: "Dirige la caméra vers l'avant de ta carte de crédit.",
  selectPaymentMethod: "Sélectionne ton mode de paiement préféré",
  creditCard: "Carte de crédit",
  unlistedPaymentMethodText:
    "Préférez-vous utiliser un mode de paiement non répertorié ?",
  addNewPayment: "Ajouter un nouveau paiement",
};