
const { height } =window.innerHeight;

export default {
  COLORS: {
    primary: "#001F2D",
    secondary: "#4D626C",
    white: "#FFFFFF",
    border: "#F3F4F8",
    grey5: "#2A2E38",
    grey4: "#555A64",
    grey3: "#82868F",
    grey2: "#ACB1B8",
    grey1: "#EAEBEB",
    color5: "#412981",
    color4: "#67549A",
    color3: "#8D7FB3",
    color2: "#CFC9DF",
    color1: "#ECEAF2",
    error: "#FF3737",
    warning: "#FFA51E",
    success: "#4ABA63",
    transparent: "transparent",
  },
  FONTS: {
    Regular:  "Regular",
    Bold: "Bold",
    Medium: "Medium",
  },
  FONT_SIZES: {
    header_title: 21,
    h1: 22,
    h2: 18,
    p: 15,
    small: 12,
    text_input: 16
  },
  BUTTONS: {
    borderRadius: 12,
    fontSize: 16,
    iconMarginRight: 14,
    primary_background: "color5",
    primary_text_color: "white",
    primary_background_pressed: "grey5",
    primary_text_color_pressed: "white",
    primary_background_disabled: "grey3",
    primary_text_color_disabled: "grey2",
    primary_background_loading: "color5",
    primary_text_color_loading: "white",
    secondary_background: "transparent",
    secondary_border_width: 1,
    secondary_border_color: "color5",
    secondary_text_color: "color5",
    secondary_background_pressed: "color2",
    secondary_border_color_pressed: "color5",
    secondary_text_color_pressed: "color5",
    secondary_background_disabled: "transparent",
    secondary_border_color_disabled: "color5",
    secondary_text_color_disabled: "color5",
    secondary_background_loading: "transparent",
    secondary_border_color_loading: "color5",
    secondary_text_color_loading: "color5",
    link_background: "white",
    link_background_pressed: "white",
    link_background_disabled: "white",
    link_text_color: "color5",
    link_text_color_pressed: "color4",
    link_text_color_disabled: "grey2",
    link_font_size: 14,
    link_line_height: 17,
    link_font_family: "Medium",
    primary_round_background: "color5",
    primary_round_background_pressed: "color4",
    primary_round_icon_color: "white",
    primary_round_icon_color_pressed: "white",
    secondary_round_background: "grey1",
    secondary_round_background_pressed: "color5",
    secondary_round_icon_color: "grey5",
    secondary_round_icon_color_pressed: "grey5",
  },
  HOME: {
    header_with_logo: false,
    bg_img_close_menu_scale: 2.1,
    bg_img_close_menu_scale_small_height: 1.75,
    bg_img_open_menu_scale: 1,
    bg_img_open_menu_scale_small_height: 0.8,
    bg_img_translate_Y_percentage: 0.25,
    bg_img_translate_X_percentage: 0,
    carousel_height: height * 0.55,
    carousel_height_small_screens: 395,
  },
  MENU: {
    background: "color3",
    text_color: "white",
    font_family: "Bold",
    language_submenu_background: "color4",
  },
  GLOBAL_SPACES: {
    paddingHorizontal: "5.7%",
  },
};
  