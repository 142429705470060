import React from "react";
import { StyleSheet, Text, View, Pressable } from "react-native-web";
import DefaultTheme from "../themes";
import {ReactComponent as Warning} from "../assets/icons/warning.svg";
import ContainerSpaceBetween from "./UI/ContainerSpaceBetween";

const DataList = ({
  onPress,
  borderAtBottom = true,
  styleOuterView,
  title,
  titleRightInfo,
  lineOneLeft,
  lineOneRight,
  lineTwoLeft,
  lineTwoRight,
  lastWideLine,
  debt,
}) => {
  return (
    <>
      <Pressable onPress={onPress}>
        <View
          style={[
            borderAtBottom && styles.container_borderBottom,
            styles.info_container,
            styleOuterView,
          ]}
        >
          <ContainerSpaceBetween style={{ paddingBottom: 12 }}>
            <View style={{ flexDirection: "row" }}>
              {debt ? (
                <Warning style={{ alignSelf: "center", marginRight: 6 }} />
              ) : null}
              <Text style={[styles.darker_text, title.style]}>
                {title.text}
              </Text>
            </View>
            {titleRightInfo && (
              <Text style={styles.lighter_text}>{titleRightInfo}</Text>
            )}
          </ContainerSpaceBetween>
          <ContainerSpaceBetween>
            <Text style={styles.lighter_text}>
              {lineOneLeft.label && (
                  lineOneLeft.label + " "+ lineOneLeft.text
              )}

            </Text>

            <Text style={[styles.lighter_text, lineOneRight.style]}>
              <Text style={{ color: lineOneRight.color }}>
                {lineOneRight.label}
              </Text>{" "}
              {lineOneRight.text}
            </Text>
          </ContainerSpaceBetween>
          {(lineTwoLeft || lineTwoRight) && (
            <ContainerSpaceBetween style={{ paddingTop: 10 }}>
              {lineTwoLeft && (
                <Text style={styles.lighter_text}>
                  <Text style={{ color: lineTwoLeft.color }}>
                    {lineTwoLeft.label}
                  </Text>{" "}
                  {lineTwoLeft.text}
                </Text>
              )}
              {lineTwoRight && (
                <Text
                  style={[
                    styles.lighter_text,
                    { color: DefaultTheme.COLORS.grey2 },
                  ]}
                >
                  {lineTwoRight.label}{" "}
                  <Text
                    style={[
                      {
                        color: lineTwoRight.color,
                        fontFamily: DefaultTheme.FONTS.Medium,
                      },
                      lineTwoRight.style,
                    ]}
                  >
                    {lineTwoRight.text}
                  </Text>
                </Text>
              )}
            </ContainerSpaceBetween>
          )}
          {lastWideLine && (
            <View style={{ paddingTop: 17 }}>
              <Text
                style={[
                  styles.lighter_text,
                  { color: lastWideLine.color },
                  lastWideLine.style,
                ]}
              >
                {lastWideLine.text}
              </Text>
            </View>
          )}
        </View>
      </Pressable>
    </>
  );
};
const styles = StyleSheet.create({
  container_borderBottom: {
    borderBottomColor: DefaultTheme.COLORS.grey1,
    borderBottomWidth: 1,
  },
  info_container: {
    paddingVertical: 11,
  },
  darker_text: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: 15,
    lineHeight: 17.78,
    color: DefaultTheme.COLORS.grey5,
  },
  lighter_text: {
    fontFamily: DefaultTheme.FONTS.Regular,
    fontSize: 12,
    lineHeight: 14.22,
    color: DefaultTheme.COLORS.grey3,
  },
});
export default DataList;
