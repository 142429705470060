import axios from "axios";
import querystring from "query-string";
import localStorageTokens from "../localStorage/tokens";
import localStorageUser from "../localStorage/user";
import utils from "../constants/utils/validate";
import Config from "../config/config";

const buybyeURL = () =>{
  return Config.buybyeURL;
}

const getToken = (token = "authToken") => {
  if (typeof window !== 'undefined') {
  return JSON.parse(localStorage.getItem(token));
  }else{
    return null;
  }
};

const dns = Config.dns;

const fetch = async (
  method,
  url,
  data = undefined,
  h = {},
  timeout = 30000,
  replaceClientId
) => {
  let authToken = await localStorageTokens.getToken(dns);
  if (!authToken.success) return authToken;
  let user = await localStorageUser.getUser();
  if (!user.success) return user;
  if (typeof user.result === "string") user.result = JSON.parse(user.result);

  const headers = {
    authtoken: authToken.result,
    clientId: user?.result?._id || replaceClientId,
  };
  let options = {
    method: method,
    url: url,
    headers: headers,
    data,
    timeout: timeout,
  };

  let response = await axios(options)
    .then((r) => utils.successResult(true, r))
    .catch((r) => utils.successResult(false, { ...r, noResponse: true }));
  if (response.success) {
    response = response.result.data;
  }
  return response;
};

const openMachine = async (model, query) => {
  let result = await fetch(
    "put",
    `${buybyeURL()}/${model}?${querystring.stringify({ data: JSON.stringify(query) })}`,
    undefined,
    {},
    60000
  );
  return result;
};

const getOrganizations = async (model, query) => {
  let result = await fetch(
    "get",
    `${buybyeURL()} /buybye/${model}?${querystring.stringify({
        data: JSON.stringify(query),
      })}`
  );
  return result;
};
// Get Shops 
const getShops = async (otherIdentifiers) => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/shops/cards?${querystring.stringify({ _a: "a", cards:[...otherIdentifiers] })}`
  );
  return result;
};
const getShop = async (idShop) => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/shop/receipt?idShop=` + idShop
  );
  return result;
};
// Cards management
const getPaymentSystem = "stripe";

const createCustomerCard = async (card) => {
  let result = await fetch(
    "post",
    `${buybyeURL()}/customer/card?payment=${getPaymentSystem}`,
    {card},
  );
  return result;
};
const getCustomerCards = async () => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/customer/card?payment=${getPaymentSystem}`
  );
  return result;
};

const setCustomerDefaultCard = async (card) => {
  let result = await fetch(
    "put",
    `${buybyeURL()}/customer/change/card?payment=${getPaymentSystem}`,
    { card }
  );
  return result;
};

const deleteCustomerCard = async (card) => {
  let result = await fetch(
    "delete",
    `${buybyeURL()}/customer/card?payment=${getPaymentSystem}`,
    { card }
  );
  return result;
};

const customerPayDebt = async (clientId) => {
  let result = await fetch(
    "post",
    `${buybyeURL()}/customer/pay/debt?payment=${getPaymentSystem}`,
  );
  return result;
};

const openBuyByeToShop = async (machineCode) => {
  let result = await fetch(
    "put",
    `${buybyeURL()}/OpenBuyByeToShop?payment=${getPaymentSystem}&machineCode=${machineCode}`,
    undefined,
    {},
    60000
  );
  return result;
};

const getTickets= async () => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/caseManagement/requester`,
  );
  return result;
};

const createTicket= async (data) => {
  let result = await fetch(
    "post",
    `${buybyeURL()}/caseManagement`,
    data
  );
  return result;
};

const getTicket= async (id) => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/caseManagement?caseManagement=${id}`,
  );
  return result;
};

const resMessage= async (data) => {
  let result = await fetch(
    "put",
    `${buybyeURL()}/caseManagement`,
    data
  );
  return result;
};

const getDebts= async () => {
  let result = await fetch(
    "get",
    `${buybyeURL()}/shops/debt`
  );
  return result;
};

const sendEmailReceipt = async (idShop, data) => {
  let result = await fetch(
    "put",
    `${buybyeURL()}/shop/` + idShop + `/receipt`,
    data,
    {},
    30000,
    data.contact
  );
  return result;
};

export default {
  fetch,
  getShops,
  getShop,
  getOrganizations,
  createCustomerCard,
  getCustomerCards,
  setCustomerDefaultCard,
  deleteCustomerCard,
  customerPayDebt,
  openMachine,
  getOrganizations,
  openBuyByeToShop,
  getTickets,
  createTicket,
  getTicket,
  resMessage,
  getDebts,
  getToken,
  sendEmailReceipt,
};

