export default {
  changeDefault: "Change preferred payment",
  choosePreferred: "Choose the preferred payment card.",
  preferredPayment: "Preferred Payment",
  makePreferredPayment: "Set as preferred payment",
  validThru: "Valid thru",
  purchasesPaidWithCard: "Purchases paid with this card",
  deletePaymentCard: "Delete payment card",
  settleDebt: "Settle debt",
  withPreferred: " with preferred payment",
  settleDebtModalTitle: "Confirm debt settlement",
  settleDebtModalTextPart1: "This operation will settle your",
  settleDebtModalTextPart2: "debt through your preferred payment - card",
  deleteCardModalTitle: "Want to Delete Credit Card?",
  deleteCardModalText:
    "You may still use the different payment methods you've provided.",
  paymentMethodMissingTitle: "Payment Method Missing",
  paymentMethodMissingText: "You must add a payment method to be able to shop.",
  skipPaymentSetupTitle: "Skip Payment Set-up?",
  skipPaymentSetupText:
    "Without a payment method set-up you won’t be able to buy.",
  enterNameInfo: "Enter the name as it appears on the card.",
  cardInformation:
    "This card allows you to pay for all purchases. You can edit this information and add different payment methods later. To validate your card, we apply a temporary authorization hold that will later be reimbursed.",
  cardInformation01: " This payment will be set as preferred.",
  paymentVerificationTitle: "Payment verification",
  paymentVerificationText:
    "To confirm the the card is valid, a small fee will be captured, and later will be refund.",
  scanCardInfo: "Please, aim the camera to the front of your credit card.",
  selectPaymentMethod: "Select your preferred payment method",
  creditCard: "Credit Card",
  unlistedPaymentMethodText: "Do you prefer to use an unlisted payment method?",
  addNewPayment: "Add new Payment"
};