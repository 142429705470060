export default {
  microStoreCodeInfo:
    "Entre le numéro à quatre chiffres figurant sur le dessus du Microstore et commence la conversation.",
  microStoreIDOrPurchaseID: "ID du Microstore ou ID d'achat",
  subject: "Sujet",
  message: "Message",
  validateTicket: "Valider le message",
  ticketConfirmationText:
    "Après confirmation, ta demande sera prise en charge dans les plus brefs délais.",
  ticketsSubjectsInfo:
    "Tu as besoin de notre aide ? Pré-sélectionne l'un des problèmes suivants.",
  purchase: "Achat",
  microstore: "Microstore",
  status: "Statut",
  generatedAt: "Généré à ",
  noTicketsTitle: "Jusqu'ici, tout va bien",
  noTicketsText:
    "Il n'y a pas de problèmes à afficher. Contacte-nous si tu rencontres des problèmes.",
  supportTeam: "Équipe d’assistance",
  writeAMessage: "Écrire un message...",
  howToBeNotified: "Comment veux-tu être informé?",
  sms: "SMS",
  email: "E-mail",
};
			
			
			
			
			
			
			