export default {
  mandatoryUpdateTitle: "App Update Required",
  mandatoryUpdateText:
    "The service cannot be used with the app version you have. Please update to be able to shop.",
  mandatoryUpdateButton: "Update app",
  optionalUpdateTitle: "App Update recommended",
  optionalUpdateText:
    "You are running an older version of the App. It is recommended that you update otherwise the App might become inaccessible.",
  optionalUpdateButton: "Continue to the App",
  optionalUpdateLinkButton: "Update app",
};