import React from "react";
import { StyleSheet, View } from "react-native-web";

const ContainerSpaceBetween = ({ children, style }) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent:"space-between",
    alignItems: "center",
  },
});

export default ContainerSpaceBetween;
