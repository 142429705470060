export default {
  hello: "Bonjour",
  languageSelector: "Choisis ta langue préférée",
  english: "Englais",
  french: "Français",
  dutch: "Néerlandais",
  headerHome: "1, 2, 3... GO!!",
  headerForgotPassword: "Mot de passe oublié",
  headerGettingStarted: "Démarrage",
  headerOpenMachine: "Ouvrir Carrefour BuyBye",
  headerMicrostoreSession: "Session Carrefour BuyBye",
  headerHelpAndLegal: "Aide et conseils",
  headerPayments: "Mode de paiement",
  headerAccountInfo: "Informations du compte",
  headerCurrentPassword: "Mot de passe actuel",
  headerPurchases: "Mes achats",
  headerPurchaseDetail: "Détail des achats",
  headerAddCreditCard: "Ajouter une carte de crédit",
  headerAccountValidation: "Validation du compte",
  headerSignUp: "S’inscrire",
  headerResolutionCenter: "Assistance",
  headerCreateTicket: "Ma Demande",
  headerMyTickets: "Assistance",
  headerChangeLanguage: "Changer de langue",
  headerPhoneNumberReview: "Vérifier le nº de téléphone",
  headerDebts: "Dettes",
};		
			
			
			
			
			
			
			
