export default {
  microStoreCodeInfo:
    "Voer het viercijferige nummer op de bovenkant van de Microstore in en vertel eens wat het probleem is.",
  microStoreIDOrPurchaseID: "MicroStore-ID of Aankoop-ID",
  subject: "Onderwerp",
  message: "Bericht",
  validateTicket: "Bericht valideren",
  ticketConfirmationText:
    "Na bevestiging wordt je aanvraag zo snel mogelijk in behandeling genomen.",
  ticketsSubjectsInfo:
    "Heeft u ons nodig om u te helpen? Selecteer vooraf een van de volgende problemen.",
  purchase: "Aankoop",
  microstore: "Microstore",
  status: "Toestand",
  generatedAt: "Gegenereerd om",
  noTicketsTitle: "Tot nu toe, zo goed",
  noTicketsText:
    "Er zijn geen tickets om te tonen. Neem contact met ons op als u problemen tegenkomt.",
  supportTeam: "Ondersteuningsteam",
  writeAMessage: "Schrijf een bericht...",
  howToBeNotified: "Hoe wil je op de hoogte worden gehouden?",
  sms: "SMS",
  email: "E-mail",
};
			
			
			
			
			
			
			