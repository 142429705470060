import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1E5BC6",
    },
    secondary: {
      main: "#EF3641",
    },
    myMessage: {
      main: "#000000bf",
    },
    otherMessage: {
      main: "#00000069",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    red: "#f53232d6",
  },
});
export default theme;
