import React from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import Config from "../../config/config";

import AppLayout from "../../appLayout/AppLayout";

let termAndConditionsUrl = Config.termAndConditionsUrl;

export default function TermsAndConditions() {
  const { t } = useTranslation();

  return (
    <AppLayout
      headerLabel={t("common:headerHelpAndLegal")}
      headerBack={true}
      backHome={false}
      style={{ backgroundColor: "#fff" }}
    >
      <View style={{ flex: 1 }}>
        <iframe
          src={termAndConditionsUrl}
          sandbox="allow-scripts"
          style={{ flex: 1 }}
          frameborder="0"
        />
      </View>
    </AppLayout>
  );
}
