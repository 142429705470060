import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native-web';

import ReactCodeInput from "react-code-input";
import DefaultTheme from '../themes';


const UnderLine = ({ cellCount, onChange }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <SafeAreaView>
      <ReactCodeInput
        value={value}
        onChange={setValue}
        fields={cellCount}
        inputStyle={styles.cellRoot}
        inputMode="number-pad"
      />
    </SafeAreaView>
  );
};

export default UnderLine;

const styles = StyleSheet.create({
  codeFiledRoot: {
    height: 60,
    marginTop: "40%",
    marginLeft: 0,
    marginRight: 0,
  },
  cellRoot: {
    width: "14%",
    height: 50,
    marginRight:8,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    border: "none",
    borderBottomColor: DefaultTheme.COLORS.grey5,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    outlineWidth: "0px",
    color: DefaultTheme.COLORS.grey5,
    fontSize: 20,
    textAlign: 'center',
  },
  cellText: {
    color: DefaultTheme.COLORS.grey5,
    fontSize: 36,
    textAlign: 'center',
  },
  focusCell: {
    borderBottomColor: DefaultTheme.COLORS.color5,
    borderBottomWidth: 2,
  },
});