export default {
  myPurchases: "Mijn aankopen",
  paymentMethods: "Betaalmethoden",
  accountInfo: "Account informatie",
  resolutionCenter: "Helpcenter",
  languageSettings: "Taalkeuze",
  logout: "Afmelden"
};
			
			
			