import React from "react";
import { StyleSheet, Text } from "react-native-web";
import DefaultTheme from "../../themes";

const TextInlineWithLinks = ({ children, style }) => {
  return <Text style={[styles.text, style]}>{children}</Text>;
};
const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 21,
    fontFamily: DefaultTheme.FONTS.Regular,
    letterSpacing: 0.25,
    color: DefaultTheme.COLORS.grey3,
  },
});

export default TextInlineWithLinks;
