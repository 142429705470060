import authService from "../services/auth-service";
import successResult from "../constants/utils/validate";
import SecureLocalStorage from "react-secure-storage";


const getUser = () => {
  try {
    return successResult.successResult(
      true,
      JSON.parse(SecureLocalStorage.getItem("user"))
    );
  } catch (e) {
    return successResult.successResult(false, "Error");
  }
};

const setUser = (data) => {
  SecureLocalStorage.setItem("user", JSON.stringify(data));
  return true;
};

const deleteUser = () => {
  SecureLocalStorage.removeItem("user");
}

const getUserLanguage = () => {
  return SecureLocalStorage.getItem("user-language");
};

const setUserLanguage = (language) => {
  SecureLocalStorage.setItem("user-language", language);
}

const removeUserLanguage = () => {
  SecureLocalStorage.removeItem("user-language");
};

export default {
  getUser,
  setUser,
  deleteUser,
  getUserLanguage,
  setUserLanguage,
  removeUserLanguage,
}; 