import alerts from "./alerts";
import authentication from "./authentication";
import buttons from "./buttons";
import common from "./common";
import errors from "./errors";
import helpAndLegal from "./helpAndLegal";
import home from "./home";
import instructions from "./instructions";
import menu from "./menu";
import microstore from "./microstore";
import payments from "./payments";
import purchases from "./purchases";
import resultScreens from "./resultScreens";
import tickets from "./tickets";
import debts from "./debts"
import updateApp from "./updateApp";

export default {
  alerts,
  authentication,
  buttons,
  common,
  errors,
  helpAndLegal,
  home,
  instructions,
  menu,
  microstore,
  payments,
  purchases,
  resultScreens,
  tickets,
  debts,
  updateApp
};
