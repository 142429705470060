export default {
  faqs: "FAQs",
  howToUseTheMicrostore: "Comment utiliser le microstore?",
  contactUs: "Nous contacter",
  subject: "Sujet",
  message: "Message",
  contactUsOkScreenTitle: "Merci à toi!",
  contactUsOkScreenText:
    "Nous apprécions ton commentaire et nous essaierons de te répondre aussi vite que possible !",
  privacyPolicy: "Politique de confidentialité",
  termsConditions: "Termes et conditions",
  howToUse01Title: "Scanne le QR code",
  howToUse01Text: "sur les portes du microstore.",
  howToUse02Title: "Attends que les portes se déverouillent",
  howToUse02Text: "Après avoir entendu un 'clac', tu peux ouvrir les portes, et commencer tes achats.",
  howToUse03Title: "Jette un coup d'œil à l'intérieur,",
  howToUse03Text:
    "prends tes produits mais n'oulie surtout pas de garder au moins une porte ouverte pendant tes achats.",
  howToUse04Title: "Tu as fini? Merci de refermer les portes",
  howToUse04Text: "Tous les produits que tu as choisis sont identifiés.",
  howToUse05Title: "Traitement du paiement",
  howToUse05Text:
    "Les détails des sessions seront disponibles dans l'onglet \"Mes achats\".",
};

			
			
			
			
			
			
			
			
			
			
			
			
				
			
			
			
			
			