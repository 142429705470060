import React from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import "./fonts.css";
import BuyByeService from "./services/buybye-service";
import LocaleContext from "./context/LocaleContext";
import "./constants/IMLocalize";

export const navigationRef = React.createRef();

const App = (props) => {
  const language = BuyByeService.getToken("locale");
  const [locale, setLocale] = React.useState(
    language ? language.locale : "en"
  );

  const value = React.useMemo(() => ({ locale, setLocale }), [locale]);

  return (
    <LocaleContext.Provider value={value}>
        {props.children}
    </LocaleContext.Provider>
  );
};

export default App;