export default {
  microstoreIDinfo:
    "Voer de MicroStore ID in (het viercijferige nummer op de bovenkant van de kast).",
  microstoreQRinfo: "Scan de QR-code op de deuren van de microstore.",
  waitTitle: "Wacht tot de deuren opengaan",
  waitText: "De deur gaat zo dadelijk openen. Je zal een klik horen.",
  doorOpenTitle: "De deuren staan open",
  doorOpenText:
    "Kies de producten die je wilt, en vergeet niet om minstens één deur open te houden terwijl je winkelt.",
  doorClosedTitle: "Geniet van je producten",
  doorClosedText:
    'Je bent vrij om te gaan. De detail van je aankopen wordt straks beschikbaar in de tab "Mijn aankopen".',
};
