export default {
  hello: "Hallo",
  languageSelector: "Kies je taal",
  english: "Engels",
  french: "Frans",
  dutch: "Nederlands",
  headerHome: "Aanmelden",
  headerForgotPassword: "Wachtwoord vergeten?",
  headerGettingStarted: "Aan de slag!",
  headerOpenMachine: "Open Carrefour Buybye",
  headerMicrostoreSession: "Carrefour Buybye sessie",
  headerHelpAndLegal: "Info",
  headerPayments: "Betaalmethoden",
  headerAccountInfo: "Account informatie",
  headerCurrentPassword: "Huidig wachtwoord",
  headerPurchases: "Mijn aankopen",
  headerPurchaseDetail: "Aankoopdetails",
  headerAddCreditCard: "Creditcard toevoegen",
  headerAccountValidation: "Account informatie",
  headerSignUp: "Aanmelden",
  headerResolutionCenter: "Helpcenter",
  headerCreateTicket: "Een probleem melden",
  headerMyTickets: "Mijn kaartjes",
  headerChangeLanguage: "Taalkeuze",
  headerDebts: "Openstaande schulden",
  headerPhoneNumberReview: "Telefoonnummeroverzicht",
};
