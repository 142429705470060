export default {
  microStoreCodeInfo:
    "Enter the four digit-number on the top of the Microstore and start the conversation",
  microStoreIDOrPurchaseID: "MicroStore ID or Purchase ID",
  subject: "Subject",
  message: "Message",
  validateTicket: "Validate Ticket?",
  ticketConfirmationText:
    "After confirmation, your request will be taken care of as soon as possible.",
  ticketsSubjectsInfo:
    "Need us to help you out? Pre-select one of the following problems.",
  purchase: "Purchase",
  microstore: "Microstore",
  status: "Status",
  generatedAt: "Generated at ",
  noTicketsTitle: "So far, so good",
  noTicketsText:
    "There are no tickets to be displayed. Contact us when you come across any issues.",
  supportTeam: "Support Team",
  writeAMessage: "Write a message...",
  howToBeNotified: "How do you want to be notified?",
  sms: "SMS",
  email: "E-mail",
};
