import i18n from "i18next";

const noResponseParams = {
  showHeader: false,
  styleToComponent: {
    marginTop: "25%",
  },
  title: i18n.t("resultScreens:noResponseTitle"),
  text: i18n.t("resultScreens:noResponseText"),
};

const notOkPageParams = (errorMsg) => {
  return {
    showHeader: false,
    styleToComponent: {
      marginTop: "25%",
    },
    title: i18n.t("resultScreens:errorTitle"),
    text: errorMsg,
  };
};

export { noResponseParams, notOkPageParams };