import React from "react";
import { StyleSheet, Text, View } from "react-native-web";
import DefaultTheme from "../themes";

const DataListHeader = ({ title }) => {
  return (
    <View style={[styles.container_borderBottom, styles.title_container]}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container_borderBottom: {
    borderBottomColor: DefaultTheme.COLORS.grey1,
    borderBottomWidth: 1,
  },
  title_container: {
    paddingTop: 20,
    paddingBottom: 15,
    backgroundColor: DefaultTheme.COLORS.white,
  },
  title: {
    fontFamily: DefaultTheme.FONTS.Medium,
    color: DefaultTheme.COLORS.grey3,
    fontSize: DefaultTheme.FONT_SIZES.p,
    lineHeight: 22.5,
  },
});
export default DataListHeader;
