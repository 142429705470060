export default {
  slide01: "Time For a Break?",
  slide02: "Looking for a snack?",
  slide03: "Grabbing a quick meal?",
  slide04: "Changing up your routine?",
  slide05: "Maybe something sweet?",
  slide06: "Or an healthier option?",
  logoutModalTitle: "Have To Leave? No Problem",
  logoutModalText: "Hope you enjoyed using our MicroStores. See you soon!",
};
