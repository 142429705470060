export const ticketSubjectsArr = [
  {
    key: "01",
    title: "Problem 1",
  },
  {
    key: "02",
    title: "Problem 2",
  },
  {
    key: "03",
    title: "Problem 3",
  },
  {
    key: "04",
    title: "Other",
  },
];
