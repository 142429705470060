import SecureLocalStorage  from  "react-secure-storage";
import successResult from "../constants/utils/validate";
import authService from "../services/auth-service";

const getToken = async (dns) => {
    let res = await authService.encryptData({dns});
    if (res.success) setToken(res.result, res.date)
    return res;
};

const getLocalToken = async () => {
   try {
     return successResult.successResult(
       true,
       JSON.parse(await SecureLocalStorage.getItem("token"))
     );
   } catch (e) {
     return successResult.successResult(false, "Error");
   }
}

const isValidToken = (token, definedMinutes) => {
  const definedMilliseconds = definedMinutes * 60 * 1000;
  const currentTime = new Date().getTime();
  const tokenCreatedTime = token ? new Date(token.time).getTime() : 0;
  const tokenTime = tokenCreatedTime + definedMilliseconds;

  return tokenTime > currentTime;
};

const setToken = async (token, date) => {
    await SecureLocalStorage.setItem(
      "token",
      JSON.stringify({ token: token, time: date })
    );
    return true;
};

const deleteToken = async() => {
    await SecureLocalStorage.removeItem("token");
}

export default {
  getToken,
  setToken,
  deleteToken,
  getLocalToken,
  isValidToken,
};