export default {
  microstoreIDinfo:
    "Enter the MicroStore ID (the four digit number on the top of the cabinet).",
  microstoreQRinfo: "Scan the QR Code on the top of the cabinet.",
  waitTitle: "Wait for the door to open",
  waitText: "The door will unlock shortly. You will hear a click.",
  doorOpenTitle: "Door is open",
  doorOpenText: "Pick the products you want but don’t forget to keep at least one door open while shopping.",
  doorClosedTitle: "Enjoy your products",
  doorClosedText:
    "You are free to go. Your purchase details will be available in the “My Purchases” menu.",
};
