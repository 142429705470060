export default {
  userNotValidated: "User Not Validated",
  emailEmpty: "Please fill the email.",
  emailInvalid: "The entered email is invalid.",
  emailDuplicated: "The email is already in use.",
  passwordEmpty: "Please fill the password.",
  passwordWrong: "Please insert the correct password.",
  currentPassword: "Enter the current password. ",
  passwordInfo:
    "Password must have at least 8 characters, at least 1 number or symbol.",
  noMatchEmailAndPassword:
    "The email and password you entered did not match our records. Please double-check and try again.",
  required: "Required. ",
  phoneInvalid: "The entered phone number is invalid.",
  phoneDuplicated: "This phone number is already in use.",
  validationCodeWrong:
    "The validation code entered is wrong, please enter the correct one.",
  validationCodeExpired: "The verification code has expired.",
  messageCharacters: "Your message must have at least 10 characters.",
  cardNumberValidation: "Please insert a valid card number.",
  cardExpiryDateValidation: "Expiry date must be this month or later.",
  cardCVCValidation: "CVC must have 3 or 4 digits.",
  invalidMicrostoreCode: "Please insert a valid ID."
};