export default {
  phoneNumber: "Phone Number",
  searchCountry: "Search country",
  forgotPassword: "Forgot Password?",
  changePassword: "Change Password",
  deleteAccountModalTitle: "Delete your account?",
  deleteAccountModalText:
    "This action will delete your account. All the stored data will be permanently deleted from our database in the next 30 days, according to our Privacy Policy.",
  name: "Name",
  nameInfo: "Enter your name as it appears on your ID",
  emailAddress: "Email Address",
  password: "Password",
  newPassword: "New Password",
  checkTermsAndPrivacy: "By checking this box, I agree to the Reckon.ai ",
  termsConditions: "Terms & Conditions",
  and: "and",
  privacyPolicy: "Privacy Policy",
  verificationCodeInfoPart1: "We’ve sent a verification code to your email",
  verificationCodeInfoPart2: "The code is valid for",
  seconds: "s",
  profileAllSetTitle: "Your Profile Is All Set",
  profileAllSetText:
    "Now that your profile is set , let’s set-up your payment method.",
  modalDuplicatedUserTitle: "It Looks Like You Already Have An Account",
  modalDuplicatedUserText01: "The email",
  modalDuplicatedUserText011: "The phone number",
  modalDuplicatedUserText02: "is already in use. Do you want to login instead?",
  modalDuplicatedUserText03:
    "Both email and phone number are already in use in different accounts. Please review the info or try to login.",
  didntReciveCode: "Didn’t receive the code?",
  or: "or",
  phoneReviewInfo: "Please insert your phone number bellow.",
  loginDeletedAccountModalTitle: "Reactivate your account?",
  loginDeletedAccountModalText:
    "It appears that your account was recently deleted. By continuing with the login procedure, the account will be reactivated and the request to delete the data will be canceled.",
}; 
