export default {
  microstoreIDinfo:
    "Entrer l'ID MicroStore (le numéro à quatre chiffres sur le dessus de l'armoire).",
  microstoreQRinfo: "Scanne le QR code sur la porte",
  waitTitle: "Attends que les portes s'ouvrent",
  waitText: "La porte se déverrouillera sous peu. Tu entendras un clic.",
  doorOpenTitle: "Les portes sont ouvertes",
  doorOpenText:
    "Choisis les produits que tu veux mais n'oublie pas de garder au moins une porte ouverte pendant tes achats.",
  doorClosedTitle: "Déguste tes produits",
  doorClosedText:
    "Tu es libre de partir. Le détail de tes achats sera disponible dans l'onglet \"Mes achats\".",
};
			
			
			
			
			
			