import React, { useEffect } from 'react';
import { StyleSheet, Animated, View, Modal, Pressable } from 'react-native-web';
import { IoClose } from 'react-icons/io5';

import Button from './Button';
import DefaultTheme from "../themes";
import H1Title from './UI/H1Title';
import Paragraph from './UI/Paragraph';

const ModalView = ({ modalVisible, setModalVisible, title, subTitle, buttons }) => {
    const slide = React.useRef(new Animated.Value(0)).current;
    const [opacity] = React.useState(new Animated.Value(0));

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 0.5 seconds
        Animated.timing(opacity, {
            toValue: 0.5,
            duration: 500,
            useNativeDriver: true,
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 0.5 seconds
        Animated.timing(opacity, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true
        }).start();
    };

    const slideUp = () => {
        Animated.timing(slide, {
            toValue: 0,
            duration: 400,
            useNativeDriver: true,
        }).start();
    }

    const slideDown = () => {
        Animated.timing(slide, {
            toValue: 500,
            duration: 0,
            useNativeDriver: true,
        }).start();
    }

    const opacityFade = {
        opacity: opacity,
    };

    const slideUpStyle = {
        transform: [
            { translateY: slide }
        ]
    };

    useEffect(() => {
        if (modalVisible) {
            fadeIn();
            slideUp();
        } else {
            fadeOut();
            slideDown();
        }
    }, [modalVisible]);

    return (
      <Modal animationType="none" transparent={true} visible={modalVisible}>
        <Animated.View style={[opacityFade, styles.containerModal]} />
        <Animated.View style={[slideUpStyle, styles.modalView]}>

          <Pressable
            style={styles.close_container}
            onPress={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <IoClose
              color={DefaultTheme.COLORS.primary}
              size={28}
            />
          </Pressable>
          <View>
            <H1Title>{title}</H1Title>
            <Paragraph>{subTitle}</Paragraph>
          </View>
          {buttons.map((button, index) => {
            return index != 0 ? (
              <View key={index} style={{ marginTop: 24 }}>
                <Button
                  key={index}
                  text={button.title}
                  onPress={button.onPress}
                  type={button.type}
                  loading={button.loading}
                  element={button.element}
                />
              </View>
            ) : (
              <Button
                key={index}
                text={button.title}
                onPress={button.onPress}
                type={button.type}
                loading={button.loading}
                element={button.element}
              />
            );
          })}
        </Animated.View>
      </Modal>
    );
}

export default ModalView;

const styles = StyleSheet.create({
  close_container: {
    alignSelf: "flex-end",
    marginBottom: 26,
  },
  containerModal: {
    position: "absolute",
    left: 0,
    right: 0,
    height: "100%",
    backgroundColor: DefaultTheme.COLORS.grey5,
  },
  modalView: {
    position: "absolute",
    bottom: 0,
    backgroundColor: DefaultTheme.COLORS.white,
    width: "100%",
    paddingTop: 26,
    paddingBottom: 64,
    paddingHorizontal: DefaultTheme.GLOBAL_SPACES.paddingHorizontal,
    borderTopRightRadius: "16px",
    borderTopLeftRadius: "16px"
  },
});
