import React from "react";
import { StyleSheet, View } from "react-native-web";

const BottomBtnContainer = ({ children, style }) => {
    return (
        <View style={[styles.container, { style }]}>{children}</View>
    )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: "12%",
  },
});

export default BottomBtnContainer;