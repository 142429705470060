export default {
  changeDefault: "Voorkeursbetaling wijzigen",
  choosePreferred: "Kies de gewenste betaalkaart.",
  preferredPayment: "Standaard betaling",
  makePreferredPayment: "Instellen als standaard betaalmethode",
  validThru: "Geldig t/m",
  purchasesPaidWithCard: "Aankopen betaald met deze kaart",
  deletePaymentCard: "Betaalkaart verwijderen",
  settleDebt: "Schuld terugbetalen",
  withPreferred: " met voorkeursbetaling",
  settleDebtModalTitle: "Betaling bevestigen",
  settleDebtModalTextPart1: "Deze operatie zal uw",
  settleDebtModalTextPart2: "schuld via uw favoriete betaalkaart",
  deleteCardModalTitle: "Creditcard verwijderen?",
  deleteCardModalText:
    "U kunt nog steeds de verschillende betaalmethoden gebruiken die u heeft opgegeven.",
  paymentMethodMissingTitle: "Betalingsmethode ontbreekt",
  paymentMethodMissingText:
    "Je moet een betaalmethode toevoegen om te kunnen winkelen.",
  skipPaymentSetupTitle: "Betaling instellen overslaan?",
  skipPaymentSetupText:
    "Zonder het instellen van een betaalmethode kunt u niet kopen.",
  enterNameInfo: "Vul de naam in zoals deze op de kaart staat.",
  cardInformation:
    "Met deze kaart kunt u alle aankopen betalen. U kunt deze informatie later bewerken en verschillende betaalmethoden toevoegen. Om uw kaart te valideren, passen we een tijdelijke autorisatie toe die later wordt terugbetaald.",
  cardInformation01: " Deze betaling wordt ingesteld als voorkeur.",
  paymentVerificationTitle: "Betaling verificatie",
  paymentVerificationText:
    "Om te bevestigen dat de kaart geldig is, wordt een kleine vergoeding in rekening gebracht en later terugbetaald.",
  scanCardInfo: "Richt de camera alstublieft op de voorkant van uw creditcard.",
  selectPaymentMethod: "Select your preferred payment method",
  creditCard: "Kredietkaart",
  unlistedPaymentMethodText:
    "Gebruik je liever een niet-vermelde betaalmethode?",
  addNewPayment: "Nieuwe betaling toevoegen",
};