import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  Dimensions,
} from "react-native-web";
import DefaultTheme from "../themes";

/* 
EXAMPLE Alert
<Alerts
    text="You have a debt of 9,99€. To continue to shop you need to settle the debt."
    type="warning"  <-- OR error OR success
    overlay={false}  <-- OR true if at HomePage Logged In
    primaryAction={[{ text: "Dismiss", action: () => {} }]} <-- OR nothing
    secondaryAction={[
        { text: "Change payment settings", action: () => {} },
    ]}  <-- OR nothing
/>
*/
const {width} = Dimensions.get("window"); 

const Alerts = (props) => {

  return (
      <View
        style={[
          styles.alert_container,
          styles[`alert_${props.type}`],
          props.overlay ? styles.fixed_alert_container : null,
        ]}
      >
        <Text
          style={[
            styles.text,
            props.primaryAction || props.secondaryAction
              ? styles.text_left
              : styles.text_center,
          ]}
        >
          {props.text}
        </Text>
        <View style={styles.actions_container}>
          {props.secondaryAction && (
            <Pressable
              style={[styles.action, styles.secondaryAction]}
              onPress={props.secondaryAction[0].action}
            >
              <Text style={styles.secondaryAction_text}>
                {props.secondaryAction[0].text}
              </Text>
            </Pressable>
          )}
          {props.primaryAction && (
            <Pressable
              style={[styles.action, styles.primaryAction]}
              onPress={props.primaryAction[0].action}
            >
              <Text style={styles.primaryAction_text}>
                {props.primaryAction[0].text}
              </Text>
            </Pressable>
          )}
        </View>
      </View>

  );
};
const styles = StyleSheet.create({
  alert_container: {
    paddingVertical: 8,
    paddingHorizontal: 34.3,
    minHeight: 60,
    justifyContent: "center",
  },
  fixed_alert_container: {
    position: "absolute",
    top: 0,
    zIndex: 90,
    width: width,
  },
  alert_error: {
    backgroundColor: DefaultTheme.COLORS.error,
  },
  alert_warning: {
    backgroundColor: DefaultTheme.COLORS.warning,
  },
  alert_success: {
    backgroundColor: DefaultTheme.COLORS.success,
  },
  text: {
    color: DefaultTheme.COLORS.white,
    fontSize: 12,
    lineHeight: 21.6,
  },
  text_center: {
    textAlign: "center",
  },
  text_left: {
    textAlign: "left",
  },
  actions_container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  action: {
    paddingVertical: 6,
    paddingHorizontal: 8,
    borderRadius: 5,
    margin: 8,
  },
  primaryAction: {
    backgroundColor: DefaultTheme.COLORS.white,
  },
  primaryAction_text: {
    color: DefaultTheme.COLORS.grey5,
    fontFamily: DefaultTheme.FONTS.Bold,
  },
  secondaryAction_text: {
    color: DefaultTheme.COLORS.white,
    fontFamily: DefaultTheme.FONTS.Bold,
  },
});
export default Alerts;
