export default {
  errorTitle: "Uh-Oh",
  userNotValidatedTitle: "Account niet gevalideerd",
  userNotValidated:
    "Uw account moet worden gevalideerd. We sturen je een sms met een validatiecode.",
  tryAgainLater: "Probeer het later opnieuw.",
  wrongMachineCode: "De ingevoerde code is verkeerd, voer a.u.b. opnieuw in.",
  cannotSatisfyRequest: "We kunnen niet aan uw verzoek voldoen",
  lookingForSolutions:
    "We zoeken momenteel naar oplossingen. Probeer het later opnieuw.",
  tryAgainToSetup: "Probeer het opnieuw om het installatieproces te voltooien.",
  noResponseTitle: "Er is iets misgegaan",
  noResponseText:
    "We konden niet aan je verzoek voldoen. Probeer het opnieuw. Als het probleem aanhoudt, probeer het dan later opnieuw.",
  errorSettlementTitle: "Betaling niet mogelijk",
  errorSettlementText:
    "Je betaling kon niet worden voltooid. Controleer het saldo van je betaalmiddel of probeer het later opnieuw.",
  successSettlementTitle: "Schulden zijn vereffend",
  successSettlementText:
    "Uw schuld is vereffend. Nu kunt u uw boodschappen hervatten.",
  successNewPasswordTitle: "Nieuw wachtwoord ingesteld",
  successNewPasswordText:
    "Helemaal klaar! Je bent nu klaar om met ons mee te doen in de wereld van slim winkelen.",
  successSignupAndCardTitle: "Je bent klaar",
  successSignupAndCardText:
    "Welkom. U bent nu klaar om deel te nemen aan de wereld van Smart Shopping.",
  successAddCardTitle: "Betaling toegevoegd",
  successAddCardText:
    "Uw betaalmethode is ingesteld en is klaar voor gebruik op uw account. Genieten!",
  errorDebtTitle: "Je hebt een schuld",
  errorDebtText:
    "Er is een schuld ontstaan ​​omdat we uw laatste aankoop niet in rekening konden brengen. Ga naar de details van de schuld en vereffen deze om opnieuw te kunnen winkelen.",
};