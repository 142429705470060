export default {
  myPurchases: "Mes Achats",
  paymentMethods: "Modes de Paiement",
  accountInfo: "Informations Sur Le Compte",
  resolutionCenter: "Assistance",
  languageSettings: "Choix de langue",
  logout: "Déconnexion",
};
			
			
			
			
			
			
			
			